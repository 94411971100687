import React from 'react';

export default function NotFound() {
    return (
        <div>
            NotFound
        </div>
    );
}

