import React from 'react';
import MyPageLink from '../../components/MyPageLink';

export default function MobileSetting() {
    return (
        <>
            <h2>설정</h2>
            <MyPageLink mobileFullClass={true}/>
        </>
    );
}

