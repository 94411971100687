import React from 'react';

export default function PasswordRe() {
    return (
        <>
            <label htmlFor="passwordRe">비밀번호 확인</label>
            <input type="text" id='passwordRe' placeholder='비밀번호를 확인해주세요' />
        </>
    );
}

